import { Link } from "gatsby";
import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby"
import { Nav, Container, Row, Col } from "react-bootstrap";

import SocialLinks from "gatsby-theme-starberry-lomondgroup/src/components/Footer/Sections/SocialLinks";
import CTAMenuLink from "gatsby-theme-starberry-lomondgroup/src/modules/cta_menu_link_module"

import { menuLinks } from "gatsby-theme-starberry-lomondgroup/src/common/utils/page_url_utils";
import { PageLinks } from "gatsby-theme-starberry-lomondgroup/src/common/page-links";

import "gatsby-theme-starberry-lomondgroup/src/components/NavMenu/NavMenu.scss";

const SearchMenu = (props) => {

  const data = useStaticQuery(graphql`
      query BurgerMenuQueryTheme{
        glstrapi {
          burgerMenus(sort:"sort:asc", where:{show_on_burgermenu:true}){
            id
            label
            custom_link
            menu{
              id
              slug
              label
              link_type
              parent{
                slug
                parent{
                  slug
                }
              }
            }
            add_menu{
              id
              label
              custom_link
              target
              menu{
                slug
                parent{
                  slug
                  parent{
                    slug
                  }
                }
              }
              sub_menus{
                label
                custom_link
                target
                menu_item{
                  slug
                  parent{
                    slug
                    parent{
                      slug
                    }
                  }
                }
              }
            }
          }
        }
  }`)

  var burgerMenus = data.glstrapi?.burgerMenus;

  //const { loading, error, data } = BurgerMenu();

  const [issubmenu, setIssubmenu] = useState("");
  const [secsubmenu, setSecSubmenu] = useState("");

  const handlerClick = (menu_id) => {
    if (menu_id !== issubmenu) {
      setIssubmenu(menu_id)
    } else {
      setIssubmenu("")
    }
  }

  const submenuhandlerClick = (menu_id) => {
    if (menu_id !== secsubmenu) {
      setSecSubmenu(menu_id)
    } else {
      setSecSubmenu("")
    }
  }

  const closePopup = (url) => { 
    if (window?.location?.pathname === url) {
      props.toggleSearch()
    }
  }

  return (
    <div className="search-block">
      <div className="search-block-top">
        <span className="close-menu" onClick={props.toggleSearch}>
          <i className="close-icon"></i>
        </span>
      </div>
      <Container>
        <div className="search-wrap">
          <Row>
            <Col className="p-xl-0 menu_wrap">
              <Nav className="search-nav">
                <ul>
                  {burgerMenus?.length > 0 &&
                    burgerMenus.map((item, i) => {
                      var menu_url = item?.custom_link ? item?.custom_link : item?.menu ? menuLinks(item?.menu) : "";
                      
                      return (
                        <li key={i} className={`${issubmenu === item.id ? "main-link-active" : ""}`}>

                          {menu_url ? <Link to={menu_url} onClick={() => { closePopup(menu_url) }}>{item.label}</Link> : <a href={"javascript:;"} onClick={() => handlerClick(item.id)}>{item.label}</a>}

                          {/* ======= Sub menu List ======= */}
                          {item?.add_menu?.length > 0 && <>

                            <i className={`list-icon ${issubmenu === item.id ? "icon-dropdown active" : "icon-dropdown"}`} onClick={() => handlerClick(item.id)}></i>

                            <div className={`dropdown-link ${issubmenu === item.id ? "open" : ""}`}>
                              <ul>
                                {
                                  item.add_menu.map((newitem, index) => {

                                    var submenu_url = newitem?.custom_link ? newitem?.custom_link : newitem?.menu ? menuLinks(newitem?.menu) : "";

                                    return (
                                      <li key={index} className={`${secsubmenu === newitem.id ? "main-link-active" : ""}`}>
                                        {submenu_url ? <Link to={submenu_url} target={newitem?.target === "new_window" ? "_blank" : ""} onClick={() => { closePopup(submenu_url) }}>{newitem.label}</Link> : <a href={"javascript:;"} onClick={() => submenuhandlerClick(newitem.id)}>{newitem.label}</a>}

                                        {/* ======= Secondary Sub menu List ======= */}
                                        {newitem?.sub_menus?.length > 0 && <>
                                          <i className={`list-icon ${secsubmenu === newitem.id ? "icon-minus" : "icon-plus"}`} onClick={() => submenuhandlerClick(newitem.id)}></i>

                                          <div className={`dropdown-link secondary_link ${secsubmenu === newitem.id ? "open" : ""}`}>
                                            <ul>
                                              {newitem?.sub_menus?.map((sec_sub_item, index) => {

                                                var sec_submenu_url = sec_sub_item?.custom_link ? sec_sub_item?.custom_link : sec_sub_item?.menu_item ? menuLinks(sec_sub_item?.menu_item) : "";

                                                return (
                                                  <li>

                                                    {sec_submenu_url ? <Link to={sec_submenu_url} onClick={() => { closePopup(sec_submenu_url) }} target={sec_sub_item?.target === "new_window" ? "_blank" : ""}>{sec_sub_item.label}</Link> : <a href={"javascript:;"}>{sec_sub_item.label}</a>}
                                                  </li>
                                                )
                                              })}
                                            </ul>
                                          </div>
                                        </>
                                        }

                                      </li>
                                    )
                                  }
                                  )}
                              </ul>
                            </div>
                          </>
                          }

                        </li>
                      )
                    }
                  )}
                </ul>
              </Nav>
            </Col>

            <Col md="12" className="social-links">
              <div className="search-link-list">
                {props?.site_config?.show_topmenu_burger_menu &&

                  props?.top_menu?.links?.length > 0 &&
                  <ul>
                    {
                      props?.top_menu?.links?.map((item, index) => {
                        return (
                          <li key={item} className="contact-title"><CTAMenuLink item={item} /></li>
                        )
                      })
                    }
                  </ul>
                }

                {props?.site_config?.show_sociallinks_burgermenu && <div className="sociallink_inner"><SocialLinks data={props.site_config} /></div>}

                <Link to={`/${PageLinks.request_valuation}/`} className="btn btn-primary valuation_btn">
                    Book a valuation 
                </Link>

              </div>

            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default SearchMenu;
