import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import CTAMenuLink from "gatsby-theme-starberry-lomondgroup/src/modules/cta_menu_link_module"
import CTABtnModule from "gatsby-theme-starberry-lomondgroup/src/modules/cta_btn_module";

import { menuLinks } from "gatsby-theme-starberry-lomondgroup/src/common/utils/page_url_utils";

const SubMenu = ({data, dropdown_content}) => {
  return (
    <>
    {data?.length > 0 && 
      <div className="submenu_outter">
        <div className="submenu">
            {data?.map((item) => {
              return(
              <ul>
                <li key={item.id} className={`sub_menu ${item?.label ? "menu_item_"+item?.label.replace(/ /g, '_').toLowerCase() : ""}`}>
                  <CTAMenuLink item={item} />
                </li> 

                {/* ===== Secondary Submenu ==== */}
                  {item?.sub_menus?.length > 0 &&
                    item?.sub_menus.map(sub_item => {
                      return(
                        <li key={sub_item.id} className={sub_item?.label ? "menu_item_"+sub_item?.label.replace(/ /g, '_').toLowerCase() : ""}>
                          <CTAMenuLink item={sub_item} />
                        </li> 
                      )
                    })
                  }
                {/* ===== Secondary Submenu ==== */}

              </ul>)
              })
            } 

            {/* {dropdown_content?.show && 
              <div className="dropdown_content">
                  <div className="title">{dropdown_content?.title}</div>
                  <div className="content">{dropdown_content?.content}</div>
                  <CTABtnModule button_cta={dropdown_content?.button_cta} />
              </div>
            } */}
        </div>
      </div>
    }
    </>
  )
}

const HeaderMenu = ({dropdown_content}) => {

  const data = useStaticQuery(graphql`
    query BurgerMenuQueryCustom{
      glstrapi {
        burgerMenus(sort:"sort:asc", where:{show_on_megamenu:true}){
          id
          label
          custom_link
          menu{
            id
            slug
            label
            link_type
            parent{
              slug
              parent{
                slug
              }
            }
          }
          add_menu{
            id
            label
            custom_link
            target
            menu{
              slug
              parent{
                slug
                parent{
                  slug
                }
              }
            }
            sub_menus{
              label
              custom_link
              target
              menu_item{
                slug
                parent{
                  slug
                  parent{
                    slug
                  }
                }
              }
            }
          }
        }
      }
  }`)

  var burgerMenus = data.glstrapi?.burgerMenus;

  //console.log("burgerMenus_data", burgerMenus)

  return (
    <>
    {burgerMenus?.length > 0 && 
      <div className="navigation">
          <ul className="parent_menu">
            {burgerMenus?.map((item, index) => {
              var menu_url = item?.custom_link ? item?.custom_link : item?.menu ? menuLinks(item?.menu) : "";

              return(
                item?.label.indexOf("valuation") > -1 ?
                <li key={item.id} className={"menu_item valuation"}>
                  <CTAMenuLink item={item} />
                  {/* {menu_url ? <Link to={menu_url}>{item.label}</Link> : <a href={"javascript:;"}>{item.label}</a>} */}
                </li>
                :
                <li key={item.id} className={item?.label ? "menu_item_"+item?.label.replace(/ /g, '_').toLowerCase() : ""}>
                  <CTAMenuLink item={item} />
                  {/* {menu_url ? <Link to={menu_url}>{item.label}</Link> : <a href={"javascript:;"}>{item.label}</a>} */}

                  {item?.add_menu?.length > 0 && 
                    <SubMenu data={item?.add_menu} dropdown_content={dropdown_content}/>
                  }
                </li>
              )
            })}         
          </ul>
      </div>
    }
    </>
  )
}

export default HeaderMenu